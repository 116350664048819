<template>
  <el-container class="field-container">
    <el-main class="main-cont border-radius-6 box-shadow-light-grey">
      <el-tabs type="border-card">
        <el-tab-pane label="微信">
          <dy-table
            style="margin: 20px 0 0 20px"
            :dataList="tableData"
            :rowList="rowList"
            height="calc(100vh - 280px)"
            :loading="loading"
            @page-change="handlePageChange"
          >
            <template #filter>
              <el-button @click="handlePageChange(1)" style="margin-right: 10px"
                >刷新数据</el-button
              >
              <el-button
                type="primary"
                @click="handleAdd"
                style="margin-right: 10px"
                >新增</el-button
              >
            </template>
            <template #labelType="{ row }">
              <span>{{ getTagName(row.labelType) }}</span>
            </template>
            <template #action="{ row }">
              <div>
                <el-button
                  type="success"
                  icon="el-icon-edit"
                  circle
                  @click="handleEdit(row)"
                >
                </el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  @click="handleDelete(row)"
                >
                </el-button>
              </div>
            </template>
          </dy-table>
        </el-tab-pane>
        <el-tab-pane label="百度账号">
          <baiduAccount />
        </el-tab-pane>
        <el-tab-pane label="巨量引擎">
          <oceanAccount />
        </el-tab-pane>
      </el-tabs>
    </el-main>

    <el-dialog
      :title="currentItem ? '编辑基数' : '新增基数'"
      :visible.sync="showAdd"
      width="350px"
      @close="() => (showAdd = false)"
    >
      <div class="setCash-box">
        <el-form label-width="100px">
          <el-form-item label="标签类型">
            <el-select
              v-model="form.labelType"
              placeholder="标签类型"
              clearable
            >
              <el-option label="朋友圈" :value="2"> </el-option>
              <el-option label="广点通GDT" :value="4"> </el-option>
              <el-option label="朋友圈-宇尘" :value="5"> </el-option>
              <el-option label="公众号" :value="8"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="现金">
            <c-input :onlyNum="true" v-model="form.cashBase"></c-input>
          </el-form-item>
          <el-form-item label="平台">
            <c-input :onlyNum="true" v-model="form.novelCashBase"></c-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <div class="cash-footer">
          <el-button @click="showAdd = false">取消</el-button>
          <el-button :loading="addLoading" type="primary" @click="handleConform"
            >确定</el-button
          >
        </div>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import baiduAccount from "./baiduAccount.vue";
import oceanAccount from "./oceanAccount";
import {
  getLabelCashBaseList,
  addLabelCashBaseList,
  editLabelCashBaseList,
  deleteLabelCashBaseList,
} from "@/api/labelCashBase";
import { labelList } from "@/assets/js/options";
export default {
  name: "index",
  data() {
    return {
      form: {},
      tableData: [],
      rowList: [
        {
          prop: "id",
          label: "id",
        },
        {
          prop: "labelType",
          label: "标签",
          slot: true,
        },
        {
          prop: "cashBase",
          label: "现金",
        },
        {
          prop: "novelCashBase",
          label: "平台",
        },
        {
          prop: "updatedTime",
          label: "修改时间",
        },
        {
          prop: "action",
          label: "操作",
          slot: true,
        },
      ],
      loading: false,
      labelList,
      showAdd: false,
      addLoading: false,
      currentItem: null,
    };
  },
  components: {
    baiduAccount,
    oceanAccount,
  },
  computed: {
    getTagName: function () {
      return (data) => {
        let tmpTag = labelList.find((item) => {
          return item.value == data;
        });
        return tmpTag.label;
      };
    },
  },
  methods: {
    handleDelete(row) {
      this.$confirm("确定要删除此数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteLabelCashBaseList(row.id).then((res) => {
          this.$message.success("删除成功");
          this.handlePageChange();
        });
      });
    },
    handleEdit(row) {
      this.currentItem = row;
      this.form = {
        labelType: row.labelType,
        cashBase: row.cashBase,
        novelCashBase: row.novelCashBase,
      };
      this.showAdd = true;
    },
    handleAdd() {
      this.currentItem = null;
      this.form = {};
      this.showAdd = true;
    },
    handleConform() {
      if (!this.form.labelType) {
        return this.$message.error("请选择标签");
      }
      if (!this.form.novelCashBase) {
        return this.$message.error("请输入平台");
      }
      if (!this.form.cashBase) {
        return this.$message.error("请输入现金");
      }
      this.addLoading = true;
      if (this.currentItem) {
        editLabelCashBaseList(this.currentItem.id, {
          ...this.form,
        })
          .then((res) => {
            this.$message.success("修改成功!");
            this.handlePageChange(1);
          })
          .finally(() => {
            this.addLoading = false;
            this.showAdd = false;
          });
      } else {
        addLabelCashBaseList({ ...this.form })
          .then(() => {
            this.$message.success("新增成功!");
            this.handlePageChange(1);
          })
          .finally(() => {
            this.addLoading = false;
            this.showAdd = false;
          });
      }
    },
    handlePageChange() {
      this.loading = true;
      getLabelCashBaseList()
        .then((res) => {
          this.tableData = res;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.handlePageChange(1);
  },
};
</script>

<style lang="scss" scoped>
.field-container {
  // height:calc(100vh - 176px);
}
.el-header {
  background-color: #fff;
}
.field-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  .el-button.new-field {
    margin-bottom: 0 !important;
  }
}
.header-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  .el-input {
    padding-right: 10px;
  }
}
.el-main {
  padding: 0px;
  overflow: hidden;
}
.el-main.main-cont {
  box-sizing: border-box;
  // padding: 20px;
  background: #fff;
}
.el-table__body-wrapper {
  height: 100%;
}
/deep/.el-tabs__content {
  padding: 0 !important;
}
</style>
