<template>
  <div>
    <el-drawer
      :visible.sync="showEdit"
      direction="rtl"
      size="450px"
      :title="isEdit ? '修改' : '新增'"
      :before-close="() => $emit('close')"
    >
      <el-form label-width="100px">
        <el-form-item label="用户名">
          <el-input v-model="form.userName" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input
            type="text"
            v-model="form.password"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="token">
          <el-input
            type="text"
            v-model="form.token"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="现金">
          <el-input
            type="text"
            v-model="form.cashBase"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="平台">
          <c-input
            :onlyNum="true"
            type="text"
            v-model="form.novelCashBase"
            style="width: 80%"
          ></c-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="() => $emit('close')">取 消</el-button>
        <el-button type="primary" :loading="loadingButton" @click="handleSubmit"
          >确 定</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { addBadiuApi, updateBadiuApi } from "@/api/account";
export default {
  props: {
    isEdit: Boolean,
    currentId: Number,
    showEdit: Boolean,
    detail: Object,
  },
  data() {
    return {
      form: {},
      loadingButton: false,
    };
  },
  watch: {
    showEdit: function (nv) {
      if (nv) {
        this.form = {
          ...this.detail,
        };
      }
    },
  },
  methods: {
    handleSubmit() {
      if (!this.form.userName) {
        this.$message.error("请输入用户名");
        return false;
      }
      if (!this.form.password) {
        this.$message.error("请输入密码");
        return false;
      }
      if (!this.form.token) {
        this.$message.error("请输入token");
        return false;
      }
      if (!this.form.cashBase) {
        this.$message.error("请输入现金");
        return false;
      }
      if (!this.form.novelCashBase) {
        this.$message.error("请输入平台");
        return false;
      }
      this.loadingButton = true;
      if (this.isEdit) {
        updateBadiuApi(this.currentId, this.form)
          .then((res) => {
            this.$message.success("修改成功");
            this.form = {};
            this.show = false;
            this.$emit("sucess");
          })
          .finally(() => {
            this.loadingButton = false;
          });
      } else {
        addBadiuApi(this.form)
          .then((res) => {
            this.$message.success("新增成功");
            this.form = {};
            this.show = false;
            this.$emit("sucess");
          })
          .finally(() => {
            this.loadingButton = false;
          });
      }
    },
  },
};
</script>

<style>
.dialog-footer {
  margin-top: 40px;
  text-align: center;
}
</style>