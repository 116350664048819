<template>
  <div>
    <div
      class="
        part
        border-radius-6
        box-shadow-light-grey
        padding-20
        height-calc-type1
      "
    >
      <div class="flex-between operation padding-bottom-20">
        <div>
          <el-button class="margin-right-twentyFour" @click="handlePageChange"
            >刷新数据</el-button
          >
          <el-input
            placeholder="用户姓名"
            v-model="userName"
            class="input-with-select margin-right-twentyFour"
            @keydown.native.enter="handlePageChange(1)"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handlePageChange(1)"
            ></el-button>
          </el-input>
          <el-select
            class="margin-right-twentyFour"
            v-model="accountChannelId"
            filterable
            placeholder="公众号"
            clearable
            @change="handlePageChange(1)"
          >
            <el-option
              v-for="item in officialList"
              :key="item.id"
              :value="item.id"
              :label="item.channelName"
            >
              <span style="float: left">{{ item.channelName }}</span>
              <span
                :style="
                  item.status
                    ? 'margin-left: 10px;color:#00bf8a'
                    : 'margin-left: 10px;color:#fd5d5a'
                "
                >{{ item.status ? "绑定" : "解绑" }}</span
              >
              <span style="float: right; margin-left: 15px; font-size: 13px"
                >{{ item.platformStr }} - {{ item.uname }}</span
              >
            </el-option>
          </el-select>
        </div>
        <el-button type="primary" @click="handleEdit(null)">新增账号</el-button>
      </div>
      <el-table
        v-loading="loading"
        :data="accountList"
        height="calc(100vh - 300px)"
        style="width: 100%"
      >
        <el-table-column prop="id" label="ID" width="100"> </el-table-column>
        <el-table-column prop="userName" min-width="120" label="用户名">
        </el-table-column>
        <!-- <el-table-column prop="token" min-width="160" label="token">
        </el-table-column> -->
        <el-table-column prop="cashBase" min-width="120" label="现金">
        </el-table-column>
        <el-table-column prop="novelCashBase" min-width="120" label="平台">
        </el-table-column>
        <el-table-column min-width="120" prop="createdTime" label="创建时间">
        </el-table-column>
        <el-table-column min-width="120" prop="updatedTime" label="修改时间">
        </el-table-column>
        <el-table-column label="操作" width="300px">
          <template slot-scope="scope">
            <el-button
              class="button-small"
              type="warning"
              @click="setCashBase(scope.row)"
              >设置基数</el-button
            >
            <el-button
              class="button-small"
              type="primary"
              @click="handleEdit(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 设置基数 -->
    <el-dialog
      title="设置基数"
      :visible.sync="showCash"
      width="350px"
      @close="() => (showCash = false)"
    >
      <div class="setCash-box">
        <el-form label-width="100px">
          <el-form-item label="现金">
            <c-input :onlyNum="true" v-model="cashForm.cashBase"></c-input>
          </el-form-item>
          <el-form-item label="平台">
            <c-input :onlyNum="true" v-model="cashForm.novelCashBase"></c-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <div class="cash-footer">
          <el-button @click="showCash = false">取消</el-button>
          <el-button type="primary" @click="handleSetCahsh">确定</el-button>
        </div>
      </span>
    </el-dialog>
    <!-- 新增编辑账号 -->
    <baiduEdit
      :showEdit="showEdit"
      :isEdit="isEdit"
      :detail="form"
      @close="() => (showEdit = false)"
      :currentId="currentId"
      @sucess="handleEditSuccess"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import baiduEdit from "./baiduEdit.vue";
import { updateCashBase } from "@/api/account";
import { getBaiduLabelCashBaseList } from "@/api/labelCashBase";
export default {
  components: {
    baiduEdit,
  },
  data() {
    return {
      loading: false,
      accountList: [],
      showEdit: false,
      accountChannelId: null,
      isEdit: false,
      userName: null,
      loadingButton: false,
      currentId: null,
      form: {},
      showList: false,
      listAccount: {
        id: null,
        userName: null,
      },
      showCash: false,
      cashForm: {},
      curInfo: {},
    };
  },
  computed: {
    ...mapGetters(["officialList"]),
  },
  methods: {
    handleEditSuccess() {
      this.showEdit = false;
      this.handlePageChange();
    },
    handleEdit(data) {
      if (data) {
        this.form = {
          userName: data.userName,
          password: data.password,
          password: data.password,
          token: data.token,
          cashBase: data.cashBase,
          novelCashBase: data.novelCashBase,
        };
        this.isEdit = true;
        this.currentId = data.id;
      } else {
        this.form = {};
        this.isEdit = false;
      }
      this.showEdit = true;
    },
    setCashBase(row) {
      this.cashForm = {
        novelCashBase: row.novelCashBase,
        cashBase: row.cashBase,
      };
      this.curInfo = row;
      this.showCash = true;
    },
    handleSetCahsh() {
      if (!this.cashForm.novelCashBase && this.cashForm.novelCashBase !== 0) {
        return this.$message.error("请输入平台");
      }
      if (!this.cashForm.cashBase && this.cashForm.cashBase !== 0) {
        return this.$message.error("请输入现金");
      }
      updateCashBase(this.curInfo.id, { ...this.cashForm }).then(() => {
        this.showCash = false;
        this.$message.success("修改成功!");
        this.handlePageChange(1);
      });
    },
    handleDetail(data) {
      this.listAccount = data;
      this.showList = true;
    },
    handlePageChange() {
      getBaiduLabelCashBaseList({
        userName: this.userName,
        accountChannelId: this.accountChannelId,
      }).then((res) => {
        this.accountList = res;
      });
    },
  },
  created() {
    this.handlePageChange();
  },
};
</script>

<style lang="scss" scoped>
.input-with-select {
  width: 200px;
}
.part {
  background: #fff;
  // padding: 0 15px;
}
.operation {
  background: #fff;
  // padding: 0 15px;
}
/deep/ .el-drawer {
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}

.dialog-footer {
  margin-top: 40px;
  text-align: center;
}
.cash-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
