import request from './config'

// 获取标签基数列表
export function getLabelCashBaseList(params) {
  return request.get('/label/cashBase', { params })
}

// 新增标签基数
export function addLabelCashBaseList(params) {
  return request.post('/label/cashBase', params)
}

// 编辑标签基数
export function editLabelCashBaseList(id, params) {
  return request.put(`/label/cashBase/${id}`, params)
}

// 删除标签基数
export function deleteLabelCashBaseList(id) {
  return request.delete(`/label/cashBase/${id}`)
}

// 获取百度数据
export function getBaiduLabelCashBaseList(params) {
  return request.get(`/baiduApi/selectCashBase`, { params })
}


// 获取巨量数据
export function getOeLabelCashBaseList(params) {
  return request.get(`/oeApi/accounts/cashBase`, { params })
}
