import { render, staticRenderFns } from "./oceanAccount.vue?vue&type=template&id=864700e4&scoped=true&"
import script from "./oceanAccount.vue?vue&type=script&lang=js&"
export * from "./oceanAccount.vue?vue&type=script&lang=js&"
import style0 from "./oceanAccount.vue?vue&type=style&index=0&id=864700e4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "864700e4",
  null
  
)

export default component.exports