<template>
  <div
    class="
      ocean-account
      border-radius-6
      box-shadow-light-grey
      padding-20
      height-calc-type1
    "
  >
    <div class="operation">
      <el-button @click="refreshData" class="margin-right-ten"
        >刷新数据</el-button
      >
      <!-- <el-select
        class="margin-right-ten"
        v-model="accountChannelId"
        filterable
        placeholder="公众号"
        clearable
        @change="initData"
      >
        <el-option
          v-for="item in officialList"
          :key="item.id"
          :value="item.id"
          :label="item.channelName"
        >
          <span style="float: left">{{ item.channelName }}</span>
          <span
            :style="
              item.status
                ? 'margin-left: 10px;color:#00bf8a'
                : 'margin-left: 10px;color:#fd5d5a'
            "
            >{{ item.status ? "绑定" : "解绑" }}</span
          >
          <span style="float: right; margin-left: 15px; font-size: 13px"
            >{{ item.platformStr }} - {{ item.uname }}</span
          >
        </el-option>
      </el-select> -->
      <!-- <el-button type="primary" @click="syncData" :loading="syncButtonLoading"
        >同步数据</el-button
      > -->
    </div>
    <div class="custom-table">
      <el-table
        v-loading="loading"
        :data="dataList"
        height="calc(100vh - 300px)"
        style="width: 100%"
      >
        <el-table-column label="id" prop="id" width="60"></el-table-column>
        <el-table-column label="账号Id" prop="advertiserId"></el-table-column>
        <el-table-column
          label="账号名称"
          prop="advertiserName"
        ></el-table-column>
        <el-table-column label="现金" prop="cashBase"></el-table-column>
        <el-table-column label="平台" prop="novelCashBase"></el-table-column>
        <el-table-column label="角色">
          <template slot-scope="scope">
            <span>{{ getRoleName(scope.row.advertiserRole) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="有效性">
          <template slot-scope="scope">
            <el-tag :type="scope.row.isValid === 0 ? 'danger' : 'success'">{{
              scope.row.isValid === 0 ? "无效" : "有效"
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createdTime"></el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="setCashBase(scope.row)"
              size="small"
              class="button-small margin-right-ten"
              >设置基数</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="设置基数"
      :visible.sync="showCash"
      width="350px"
      @close="() => (showCash = false)"
    >
      <div class="setCash-box">
        <el-form label-width="100px">
          <el-form-item label="现金">
            <c-input :onlyNum="true" v-model="cashForm.cashBase"></c-input>
          </el-form-item>
          <el-form-item label="平台">
            <c-input :onlyNum="true" v-model="cashForm.novelCashBase"></c-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <div class="cash-footer">
          <el-button @click="showCash = false">取消</el-button>
          <el-button type="primary" @click="handleSetCahsh">确定</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { editCaseBase } from "@/api/extend.js";
import { getOeLabelCashBaseList } from "@/api/labelCashBase";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dataList: [],
      loading: false,
      accountChannelId: null,
      isShowDialog: false,
      isShowAccountDetail: false,
      curInfo: {},
      syncButtonLoading: false,
      loadingFresh: false,
      showCash: false,
      cashForm: {},
    };
  },
  computed: {
    ...mapGetters(["officialList"]),
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.loading = true;
      getOeLabelCashBaseList({
        accountChannelId: this.accountChannelId,
      })
        .then((res) => {
          this.dataList = res;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    refreshData() {
      this.initData();
    },
    handleSetCahsh() {
      if (!this.cashForm.novelCashBase) {
        return this.$message.error("请输入平台");
      }
      if (!this.cashForm.cashBase) {
        return this.$message.error("请输入现金");
      }
      editCaseBase(this.curInfo.id, { ...this.cashForm }).then(() => {
        this.showCash = false;
        this.$message.success("修改成功!");
        this.initData();
      });
    },
    getRoleName(roleId) {
      let roleObj = {
        1: "普通广告主",
        2: "账号管家",
        3: "一级代理商",
        4: "二级代理商",
        6: "星图账号",
      };
      return roleObj[roleId];
    },
    setCashBase(row) {
      this.cashForm = {
        novelCashBase: row.novelCashBase,
        cashBase: row.cashBase,
      };
      this.curInfo = row;
      this.showCash = true;
    },
    cancel() {
      this.handleClose();
    },
    handleClose() {
      this.isShowDialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.ocean-account {
  background: #fff;
}

.operation {
}
.custom-table {
  margin-top: 20px;
}
.button-list {
  margin-top: 20px;
}
.cash-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
